.weekCalendar {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-left: 125px;
    padding-top: 28px;
    color: #6b6b6b;
}

.weekCalendar * {
    box-sizing: border-box;
}

.weekCalendar__header {
    position: absolute;
    height: 28px;
    line-height: 28px;
    z-index: 10;
    top: 0;
    padding-left: 125px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}

.weekCalendar__scaleHeader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    width: 125px;
    height: 28px;
    background-color: #f7f7f7;
    border: 1px solid #b7b7b7;
    line-height: 28px;
    color: #6b6b6b;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
}

.weekCalendar__headerWrapper {
    height: 100%;
    border-top: 1px solid #b7b7b7;
    border-bottom: 1px solid #b7b7b7;
    background-color: #f7f7f7;
    color: #6b6b6b;
}

.weekCalendar__headerColumn {
    height: 100%;
    display: inline-block;
    border-right: 1px solid #b7b7b7;
}

.weekCalendar__scaleColumn {
    position: absolute;
    z-index: 9;
    left: 0;
    padding-top: 28px;
    width: 125px;
    border-right: 1px solid #b7b7b7;
    border-left: 1px solid #b7b7b7;
    text-align: center;
}

.weekCalendar__scaleCell {
    background-color: #fff;
    border-bottom: 1px solid #b7b7b7;
}

.weekCalendar__content {
    position: relative;
    width: auto;
    overflow: auto;
    max-height: 600px;
}

.weekCalendar__overlay {
    position: absolute;
}

.weekCalendar__overlay_status_selection {
    background-color: rgba(249, 105, 14, 0.2);
}

.weekCalendar__status_selection .weekCalendar__overlay {
    pointer-events: none;
}

.calendarBody {
    position: relative;
    display: table;
    table-layout: fixed;
    min-width: 100%;
}

.calendarBody__row {
    display: table-row;
}

.calendarBody__cell {
    background-color: #fff;
    border-bottom: 1px solid #b7b7b7;
}

.calendarBody__column {
    display: table-cell;
    border-right: 1px solid #b7b7b7;
    min-width: 100px;
}

.dayCell {
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.dayCell:hover {
    cursor: pointer;
    background-color: rgba(249, 105, 14, 0.4);
    border-color: #f9690e;
}

.event {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    color: #6b6b6b;
    background-color: rgba(139, 195, 74, 0.4);
}

.calendarModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 100px;
    z-index: 100;
}

.calendarModal__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.calendarModal__content {
    position: relative;
    margin: auto;
    border: 1px solid #b7b7b7;
    background: #fff;
    padding: 10px;
    border-radius: 3px;
}

.customModal__text {
    text-align: center;
    margin-bottom: 5px;
    white-space: nowrap;
}

.customModal__input {
    margin-bottom: 10px;
    width: 100%;
}

.customModal__button {
    display: inline-block;
    padding: 3px 6px;
    color: #fff;
    border: 1px solid rgba(249, 105, 14, 0.8);
    margin-bottom: 5px;
    background-color: rgba(249, 105, 14, 0.8);
}

.customModal__button:focus,
.customModal__button:hover {
    cursor: pointer;
    background-color: rgba(249, 105, 14, 0.6);
}

.customModal__button_float_right {
    float: right;
}
